import React, { } from 'react';
import '../index.css';
import logo from '../Images/company.png';   

const MyBoxComponent = ({boxWidth,insideComp}) =>
{
    return (<div>
                <div className='hero is-fullheight backDiv ' style={{"height" : "100%"}} >
                    <div className='hero-body is-flex-direction-column' style={{"height" : "80%"}}>
                        <div style={{"width" : "20%"}}>
                            <figure className="image is-3by1">
                                    <img src={logo} alt='Company Logo'/>
                            </figure>
                        </div><br/>
                        <label className='title is-4 has-text-white mb-1'>BitSonic System</label>
                        <div className='hero box has-background-white' style={{"width" : "85%",
                            "maxWidth": (boxWidth == undefined ? "450px" : boxWidth),"height":"80%","maxHeight":"550px"}}>
                            
                            {insideComp}
                        </div>
                    </div>
                </div>
            </div>);
}
export default MyBoxComponent;