import React,{useState} from 'react';
import '../index.css';
import { useDispatch,useSelector} from "react-redux";
import { changeFrame } from '../redux/frame';

const ModalYesNo = ({modalYesNo,setModalYesNo}) =>
{
    const dispatch = useDispatch();
    const { errorMsg } = useSelector((state) => state.frame);
    return (<div className={"modal "+(modalYesNo? "is-active" : "")}>
                <div className="modal-background"></div>
                <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Are You Sure?</p>
                </header>
                <section className="modal-card-body">
                  <h1 className='subtitle'>{errorMsg[0]}</h1>
                  <h1 className='subtitle'>{errorMsg[1]}</h1>
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={() => errorMsg[2] != "" ? dispatch(changeFrame( errorMsg[2])) : setModalYesNo(false)}>No</button>
                    <button className="button is-success" onClick={() => errorMsg[3] != "" ? dispatch(changeFrame( errorMsg[3])) : setModalYesNo(false)}>Yes</button>
                </footer>
                </div>
            </div>);
}
export default ModalYesNo;
 