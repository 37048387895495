import React, {  } from 'react';
import ReactDOM from 'react-dom';
import {Provider ,useSelector} from "react-redux";
import store from "./redux/store";
import './index.css';
import 'bulma/css/bulma.min.css';
import MainMenuCom from './Components/EmailRecoCom'
import AutoficationComponent from './Components/AutoficationCom.js';
import EmailNotFoundComponent from './Components/EmailNotFoundCom.js';
import GoogleAuthTutorialComponent from './Components/GoogleAuthTutorialComp';
import GoogleAuthComponent from './Components/GoogleAuthComp';
import LoginComp from './Components/LoginComp'
import { CookiesProvider } from "react-cookie";
import './index.scss';
import EmailRecoComponent from './Components/EmailRecoCom.js';
import ResetPassComponent from './Components/ResetPasswordCom';
import ChooseWebComponenet from './Components/ChosseWeb';
const MainPage = () =>
{
    const { frame } = useSelector((state) => state.frame) ;
    return  (
    <>
      {frame === "EmailReco" ? <EmailRecoComponent /> : 
      (frame === "Autofication" ? <AutoficationComponent /> : 
      (frame === "EmailNotFound" ? <EmailNotFoundComponent /> : 
      (frame === "GoogleAuthTutorial" ? <GoogleAuthTutorialComponent /> :
      (frame == "ResetPass" ? <ResetPassComponent/> :
      (frame == "GoogleAuth" ? <GoogleAuthComponent/>:
      (frame == "Login" ? <LoginComp/> :
      (frame == "ChosseWeb" ? <ChooseWebComponenet/> : <></>)))))))}
      
      <p className={'subtitle is-5 has-text-white'} style={{"position" : "absolute","bottom" : "10px" ,"right" : "20px","zIndex" : 9999}}>© 2023 Bitsonic.ai</p>
    </>
   );
}

ReactDOM.render(
    <Provider store={store}>
        <CookiesProvider>
          <MainPage />
        </CookiesProvider>
    </Provider>,
  document.getElementById('root')
);
