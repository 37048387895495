import React, { useState,useEffect } from 'react';
import '../index.css';
import logo from '../Images/company.png';   
import { useDispatch,useSelector} from "react-redux";
import {changeEmail,changeRemember, changePass,changeFrame,changeError, changeHasGoogleAuth} from "../redux/frame";
import { useCookies } from "react-cookie";
import { ApiPyBackend } from '../Data/functions';
import MyBoxComponent from './MyBoxCom';

function validateEmail(email) 
{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && email !== "";
}

const EmailRecoComponent = () =>
{
    const [emailStatus,setEmailStatus] = useState("Email Not in The correct format");
    const { email } = useSelector((state) => state.frame);
    const [buttonLoad, setbuttonLoad] = useState("");

    const dispatch = useDispatch();

    const emailChanged = (e) =>
    {
        dispatch(changeEmail(e.target.value.trim().toLowerCase()));
        setEmailStatus(validateEmail(e.target.value.trim().toLowerCase()) ? "" : "Email Not in The correct format");
    }
    const sendEmail = async () =>
    {
        setbuttonLoad(" is-loading");
        var res = await ApiPyBackend("user/"+email,"GET",true);
        if(res.status == 404)
        {
            setbuttonLoad("");
            dispatch(changeError(["The eMail you entered is not registered with us. Please check for typos, or contact us at","data@bitsonic.ai","to set up an account."]));
            dispatch(changeFrame("EmailNotFound"));
        }
        else if(res.status == 200)
        {
            res = res.data;
            if(!res["hasPassword"] || !res["googleAuthenticatorSet"])
            {
                dispatch(changeFrame("Autofication"));
                dispatch(changeHasGoogleAuth(res["googleAuthenticatorSet"]))
                setbuttonLoad("");
            }
            else
                dispatch(changeFrame("Login"));
        }
        
    }
    const submitDisable = emailStatus !== "";
    return (<MyBoxComponent insideComp={<>
                <div>
                    <center><label className='subtitle is-4'>Client Identification</label></center>
                    <hr/>
                </div>
                <div>
                    <label className="label is-size-5">Email</label>
                    <div className="control has-icons-left mb-5">
                        <input className={emailStatus === "" ? "input is-medium  is-success is-rounded" : "input is-medium is-danger is-rounded"} type="email" placeholder="e.g. example@example.com" title={emailStatus} onChange={(e) =>  emailChanged(e)} autoFocus onKeyDown={(e) => e.key == 'Enter' && !submitDisable ? sendEmail() : null}/>
                        <span className="icon is-medium is-left"><i className="fas fa-envelope"></i></span>
                    </div>
                    <button className={"button is-medium is-primary is-fullwidth" + buttonLoad} onClick={() => sendEmail()} title={emailStatus} disabled={submitDisable}>Submit</button>
                </div>
                <hr className='m-3'></hr>
                <div className='columns m-0 is-vcentered'>
                    <div className='column m-0 paddingless'><label className="label is-size-7">Contact Us - <a href='mailto:data@bitsonic.ai'>data@bitsonic.ai</a></label></div>
                </div>
            </>}></MyBoxComponent>);
}
export default EmailRecoComponent;