import React,{useEffect,useState} from 'react';
import '../index.css';
import logo from '../Images/company.png';   
import googleSrc from '../Images/GooglePlay.svg'
import appleSrc from '../Images/AppStore.svg'
import QRCode from "react-qr-code";
import { ApiPyBackend } from '../Data/functions';
import { useDispatch, useSelector } from 'react-redux';
import MyBoxComponent from './MyBoxCom';
import { changeError, changeFrame } from '../redux/frame';
import ModalYesNo from './ModalYesNo';

const GoogleAuthTutorialComponent = () =>
{
    const { email,password } = useSelector((state) => state.frame);
    const [qrCode,setQrCode] = useState("hey");
    const [modalYesNo,setModalYesNo] = useState(false);
    
    const dispatch = useDispatch();
    useEffect( () =>
    {
        const getQR = async() =>
        {
            var res = await ApiPyBackend("user/"+email+"/google-authenticator/activate/","POST",false,{"password" : password})
            if(res.status == 200)
                setQrCode(res.data.qr_code);                
        }
        getQR();
    },[])
    return (<MyBoxComponent insideComp={<>
                <ModalYesNo modalYesNo={modalYesNo} setModalYesNo={setModalYesNo}/>
                <div className='fitt mb-4'>
                    <center>
                        <h1 className='title is-4'>Two-Factor Authentication(2FA)</h1>
                        <h1 className='subtitle is-6 mb-2'>For creating 2FA follow this instruction:</h1>
                    </center>
                    <h1 className='subtitle is-6 '>1.Download <b>Google Authenticator</b> to your phone</h1>
                    <div className='columns is-vcentered'>
                        <div className='column'><a href={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"} target="_blank" ><img src={googleSrc} width="200px" /></a></div>
                        <div className='column'><a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" ><img src={appleSrc}  width="140px"/></a></div>
                    </div>
                    <h1 className='subtitle is-6 '>2.Scan the QR with the app</h1>
                    <center><QRCode value={qrCode} style={{ height: "20%", width: "30%" }}/></center>
                    <button className='button is-fullwidth is-link is-light mt-2' onClick={() => { 
                        setModalYesNo(true);
                        dispatch(changeError(["Are you sure you want to continue?","Did you scan the QR at the Google Authenticator app?","","GoogleAuth"]))}}>Next</button>
                </div>
            </>}></MyBoxComponent>);
}
export default GoogleAuthTutorialComponent;
 