var Secret_Key = '87b9a7bec9fd98d2392e1ef536df974304bc27c5ef2820ef1468a94d425e4d57e6e48c4f14daf6cd297180a5cb31da9f6ceb8149547d5d0e11acb018391b1c53'
var pythonUrl = "",Nodeurl = "",originFolder = "";

if (window.location.href.includes("localhost")) {
    originFolder = "C:/Users/Gad/Documents/GitHub/try/Files/";
    Nodeurl = "http://localhost:8090"
    pythonUrl = "http://127.0.0.1:5100";
    
}
else 
{
    originFolder = "/mnt/disks/birddata2/birddata/";
    Nodeurl      = "https://api.bademeister-jan.pro:453"
    pythonUrl    = "https://api.bademeister-jan.pro:336";
}

export {Secret_Key,pythonUrl};