import React,{useState} from 'react';
import '../index.css';
import { useDispatch,useSelector} from "react-redux";
import {changeFrame, changePass, changeRemember} from "../redux/frame";
import MyBoxComponent from './MyBoxCom';
import { ApiPyBackend } from '../Data/functions';

var checkIcon = <span className="icon has-text-success" ><i className="fa-solid fa-check" /></span>
var XIcon = <span className="icon has-text-danger"><i className="fa-solid fa-x"/></span>

const isUpperCase = (string) => /[A-Z]+/.test(string);
const isLower = (string) => /[a-z]+/.test(string);
const isSpecialCase = (string) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);
const isNumeric = (string) => /[0-9]+/.test(string);

function validatePass(pass,dispatch)
{
    var aryOfProblems = {"Upper" : isUpperCase(pass),"Lower" : isLower(pass),"Special" : isSpecialCase(pass)
    ,"Numeric" : isNumeric(pass),"Length" : pass.length >= 16}
    if(Object.values(aryOfProblems).every((x) => x))
        dispatch(changePass(pass));
    return aryOfProblems;
}
const ResetPassComponent = () =>
{
    const [passStatus,setPassStatus] = useState({"Upper" : false,"Lower" :false,"Special" :false
    ,"Numeric" : false,"Length" : false});
    const { email,password ,hasGoogleAuth} = useSelector((state) => state.frame);
    const [confirmPassword,setConfirmPassword] = useState("");
    const [buttonLoad, setbuttonLoad] = useState("");

    const dispatch = useDispatch();
    const resetPass = async() =>
    {
        setbuttonLoad(" is-loading");
        var res = await ApiPyBackend("user/"+email+"/password/","PUT",false,{"password" : password});
        setbuttonLoad("");
        if(res.status == 200)
        {
            if(!hasGoogleAuth)
                dispatch(changeFrame("GoogleAuthTutorial"));
            else
                dispatch(changeFrame("GoogleAuth"));
        }
        else if(res.status == 403)
            alert(res.data.error);
        
    }
    const submitDisable = (!Object.values(passStatus).every((x) => x)) || confirmPassword !== password;
    return (<MyBoxComponent insideComp={<>
                <div className='fitt'>
                    <label className="label is-size-5">Email</label>
                    <div className="control has-icons-left mb-3">
                        <input className={"input is-medium  is-success is-rounded"} value={email} disabled={true}/>
                        <span className="icon is-medium is-left"><i className="fas fa-envelope"></i></span>
                    </div>
                    <label className="label is-size-5">{"New Password"}</label>
                    <div style={{"position" : "relative"}}>
                        <div className='box' style={{"position" : "absolute","left" : "-240px","width" : "200px","height" : "158px","top" : "10px"}}>
                            <div className='triangleBox'/>
                            <div className='columns mb-1'>
                                <div className='column is-narrow' style={{"paddingBottom" : "0","paddingTop" : "0"}}>{passStatus["Upper"] ? checkIcon : XIcon }</div>
                                <div className='column is-narrow' style={{"paddingBottom" : "0","paddingTop" : "0"}}>Upper Case</div>
                            </div>
                            <div className='columns mb-1'>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>{passStatus["Lower"] ? checkIcon : XIcon }</div>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>Lower Case</div>
                            </div>
                            <div className='columns mb-1'>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>{passStatus["Special"] ? checkIcon : XIcon }</div>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>Special Case</div>
                            </div>
                            <div className='columns mb-1'>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>{passStatus["Numeric"] ? checkIcon : XIcon }</div>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>Numeric</div>
                            </div>
                            <div className='columns mb-1'>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>{passStatus["Length"] ? checkIcon : XIcon }</div>
                                <div className='column is-narrow'  style={{"paddingBottom" : "0"}}>Length 16</div>
                            </div>
                        </div>
                    </div>
                    <div className="control has-icons-left mb-3">
                        <input className={Object.values(passStatus).every((x) => x) ? "input is-medium  is-success is-rounded" : "input is-medium is-danger is-rounded"} type="password" placeholder="••••••••••••" onChange={(e) => {dispatch(changePass(e.target.value));setPassStatus(validatePass(e.target.value,dispatch))}} autoFocus
                        onKeyDown={(e) => e.key == 'Enter' && !submitDisable ? resetPass() : null}/>
                        <span className="icon is-medium is-left"><i className="fa-solid fa-lock"/></span>
                    </div>
                    <label className="label is-size-5">{"Confirm Password"}</label>
                    <div className="control has-icons-left mb-3">
                        <input className={confirmPassword === password ? "input is-medium  is-success is-rounded" : "input is-medium is-danger is-rounded"} type="password" placeholder="••••••••••••" onChange={(e) => {setConfirmPassword(e.currentTarget.value)}}
                        onKeyDown={(e) => e.key == 'Enter' && !submitDisable ? resetPass() : null}/>
                        <span className="icon is-medium is-left"><i className="fa-solid fa-lock"/></span>
                    </div>
                    <button className={"button is-medium is-primary is-fullwidth"+buttonLoad} onClick={() => resetPass()} disabled={submitDisable}>Submit</button>
                    <hr className='m-2'></hr>
                    <div className='columns m-0 is-vcentered'>
                            <div className='column m-0 paddingless'><label className="label is-size-7">Contact Us - <a href='mailto:data@bitsonic.ai'>data@bitsonic.ai</a></label></div>
                            <div className='column m-0 paddingless'><button className="button is-link is-light is-pulled-right" onClick={() => dispatch(changeFrame("EmailReco"))}>Back</button></div>
                    </div>
                </div>
            </>}></MyBoxComponent>);
}
export default ResetPassComponent;
 