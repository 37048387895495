import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "frame",
  initialState:
  {
    frame : "EmailReco",
    email : "",
    remember : false,
    code : "",
    errorMsg : ["","",""],
    version : "V0.0953",
    password : "",
    hasGoogleAuth : true,
    webs : []
  },
  reducers: 
  {
    changeFrame(state,action){state.frame = action.payload;},
    changeEmail(state,action){state.email = action.payload;},
    changeRemember(state,action){state.remember = action.payload;},
    changeCode(state,action){state.code = action.payload;},
    changeError(state,action){state.errorMsg = action.payload;},
    changePass(state,action){state.password = action.payload;},
    changeHasGoogleAuth(state,action){state.hasGoogleAuth = action.payload;},
    setWebs(state,action){state.webs = action.payload;}
  }
});

export const {changeFrame,changeEmail,changeRemember,changeData,changeCode,changeError,changePass,changeHasGoogleAuth,setWebs} = mySlice.actions;
export default mySlice.reducer;
