import { Secret_Key,pythonUrl } from "./variables";
import axios from 'axios';

const ApiPyBackend = async (call,method,secret=false,body={}) =>
{
    var header = (secret ? ({"headers": {'Secret-Key': Secret_Key}}) : {});
    try
    {
        var req = await axios({method : method,url:pythonUrl+"/"+call,data : body,...header})
        return req
    }
    catch(error)
    {
        return error.response;
    }
    
}

export {ApiPyBackend};