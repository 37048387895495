import React, { } from 'react';
import '../index.css';
import { useSelector} from "react-redux";
import MyBoxComponent from './MyBoxCom';

const ChooseWebComponenet = () =>
{
    const { webs } = useSelector((state) => state.frame);
    return (<MyBoxComponent insideComp={<>
               <div className='buttons is-centered'>
                    {webs.includes("Client Portal") ? <button className='button is-large is-primary is-light ' onClick={() => window.location.href = "https://portal.bitsonic.ai/"}>Client Portal</button> : <></>}
                    {webs.includes("Flyway") ? <button className='button is-large is-info is-light ' onClick={() => window.location.href = "https://flyway.bitsonic.ai/"}>Flyway</button> : <></>}
               </div>
               <div className='buttons is-centered'>
                    {webs.includes("SongBird") ? <button className='button is-large is-success is-light ' onClick={() => window.location.href = "https://songbird.bitsonic.ai/"}>Songbird</button> : <></>}
                    {webs.includes("Admin Portal") ? <button className='button is-large is-danger is-light ' onClick={() => window.location.href = "https://admin.bitsonic.ai/"}>Admin portal</button> : <></>}
                    {webs.includes("Admin Portal") ? <button className='button is-large is-primary is-light ' onClick={() => window.location.href = "https://portal-qa.bitsonic.ai/"}>Portal-QA</button> : <></>}
               </div>
            </>}></MyBoxComponent>);
}
export default ChooseWebComponenet;