import React, { useState,useEffect } from 'react';
import '../index.css';
import useDigitInput from 'react-digit-input';
import { useDispatch,useSelector} from "react-redux";
import {changeFrame, changeError, changeCode, setWebs} from "../redux/frame";
import { useCookies } from "react-cookie";
import { useTimer } from 'use-timer';
import MyBoxComponent from './MyBoxCom';
import { ApiPyBackend } from '../Data/functions';

const GoogleAuthComponent = () =>
{
    const { email,password } = useSelector((state) => state.frame);
    const [buttonLoad, setbuttonLoad] = useState("");
    const [codeState, setcodeState] = useState(true);
    const [,setCookie,] = useCookies(["jwt"]);
    const [CookieRedir,,] = useCookies(["redirection"])
    const dispatch = useDispatch();
    const [value, onChange] = useState('');
    const digits = useDigitInput(
    {
        acceptedCharacters: /^[0-9]$/,
        length: 6,
        value,
        onChange,
    });
    const checkCode = async () =>
    {
        setbuttonLoad(" is-loading");
        var res = await ApiPyBackend("user/"+email+"/login/","POST",false,{"otc" : value,"password" : password});
        setbuttonLoad("");
        if(res.status == 200)
        {
            setCookie("jwt",res.data.jwt,{"domain" : CookieRedir.redirection != undefined && CookieRedir.redirection.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
            dispatch(setWebs(JSON.parse(res.data.sites)));

            if(true || CookieRedir.redirection.includes("auth.bitsonic.ai") || CookieRedir.redirection.includes("http://localhost:3001"))
                dispatch(changeFrame("ChosseWeb"));
            else    
                window.location.href = CookieRedir.redirection;
        }
        else
            setcodeState(false);
    }
    const sendResetGoogle = async  () =>
    {
        var res = await ApiPyBackend("user/"+email+"/google-authenticator/forgot/","POST");
        if(res.status === 200)
            alert("a request has been sent!")
    }
    useEffect(() =>
    {
        if(value.trim().length >= 6)
           checkCode();
    },[value])
    return (<MyBoxComponent boxWidth={"600px"} insideComp={<>
                <div>
                    <center>
                        <label className='subtitle is-4'>Google Authenticator Code</label>
                    </center>
                    <hr/>
                        <label className='subtitle is-5'>Please enter code sent to your <b> Google authenticator app</b></label><br/><br/>
                        <center>
                            <div className="input-group">
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'} style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal" autoFocus {...digits[0]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal"          {...digits[1]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal"          {...digits[2]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal"          {...digits[3]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal"          {...digits[4]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal"          {...digits[5]} />
                            </div>
                        </center>
                        {codeState ? <></> : <><label className='subtitle is-7 has-text-danger'><b>Wrong Code </b>please check again.</label></>}<br/>
                </div>
                <button className={"button is-medium is-primary is-fullwidth"+buttonLoad} onClick={() => checkCode()}>Log In</button>
                <hr/>
                <div>
                    <div className='buttons is-pulled-left mb-0 pb-0'>
                        <button className="button is-link is-light" onClick={() => dispatch(changeFrame("EmailReco"))}>Back</button>
                    </div>
                    <a className='is-pulled-right mr-3 mt-2' onClick={() => sendResetGoogle()}>Forgot Your Google Auth?</a>
                </div>
                
            </>}></MyBoxComponent>);
}
export default GoogleAuthComponent;
