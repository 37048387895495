import React, { useState,useEffect } from 'react';
import '../index.css';
import useDigitInput from 'react-digit-input';
import { useDispatch,useSelector} from "react-redux";
import {changeFrame, changeError, changeCode} from "../redux/frame";
import { useCookies } from "react-cookie";
import { useTimer } from 'use-timer';
import MyBoxComponent from './MyBoxCom';
import { ApiPyBackend } from '../Data/functions';

const AutoficationComponent = () =>
{
    const { email } = useSelector((state) => state.frame);
    const [buttonLoad, setbuttonLoad] = useState("");

    const [codeState, setcodeState] = useState(true);
    const { time, start } = useTimer({
        initialTime: 180,
        timerType: 'DECREMENTAL',
        endTime : 0,
        onTimeOver: () => 
        {
            dispatch(changeError(["The login code has expired. Please try again."]));
            dispatch(changeFrame("EmailNotFound"));
            
        },
    });

    const dispatch = useDispatch();
    const [value, onChange] = useState('');
    const digits = useDigitInput(
    {
        acceptedCharacters: /^[0-9]$/,
        length: 5,
        value,
        onChange,
    });
    useEffect(() => 
    {
        const resetPass = async () =>
        {
            var res = await ApiPyBackend("user/"+email+"/password/reset","POST",true);
            if(res.status !== 200)
            {
                if(res.status == 404)
                    alert("Email wasn't found in the system")
                else if(res.status == 500)
                    alert("Email was found in the system but the code couldn't be sent")
                else
                    alert("Critical Error")
            }
        }
        resetPass();
        start();
    },[]);
    const checkCode = async () =>
    {
        setbuttonLoad(" is-loading");
        var res = await ApiPyBackend("user/"+email+"/password/","PUT",false,{"otc" : value});
        setbuttonLoad("");
        if(res.status == 403)
        {
            dispatch(changeFrame("ResetPass"));
            dispatch(changeCode(value));
        }
        else if(res.status == 401)
            setcodeState(false);
    }
    useEffect(() =>
    {
        if(value.trim().length >= 5)
           checkCode();
    },[value])
    return (<MyBoxComponent boxWidth={"600px"} insideComp={<>
                <div>
                    <center>
                        <label className='subtitle is-4'>Login Code</label>
                    </center>
                    <hr/>
                        <label className='subtitle is-5'>Please enter code sent to <b>{email}</b></label><br/>
                        <label className='subtitle is-5'>Code expires in: <b>{time} seconds</b></label><br/><br/>
                        <center>
                            <div className="input-group">
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'} style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal" autoFocus {...digits[0]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'} style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal" {...digits[1]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal" {...digits[2]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal" {...digits[3]} />
                                <input className={codeState ? 'input is-large is-rounded' : 'input is-rounded is-danger'}  style={{"textAlign" : "center","fontSize" : "28px","width":"80px"}} inputMode="decimal" {...digits[4]} />
                            </div>
                        </center>
                        {codeState ? <></> : <><label className='subtitle is-7 has-text-danger'><b>Wrong Code </b>please check again.</label></>}<br/>
                </div>
                <button className={"button is-medium is-primary is-fullwidth"+buttonLoad} onClick={() => checkCode()}>Submit</button>
                <hr/>
                <div className='buttons'>
                    <button className="button is-link is-light" onClick={() => dispatch(changeFrame("EmailReco"))}>Back</button>
                </div>
            </>}></MyBoxComponent>);
}
export default AutoficationComponent;
