import React from 'react';
import '../index.css';
import logo from '../Images/company.png';   
import { useDispatch,useSelector} from "react-redux";
import {changeFrame} from "../redux/frame";
import MyBoxComponent from './MyBoxCom';

/**
 * EmailComponent
 * @return Email not found Component
 */
const EmailComponent = () =>
{
    const dispatch = useDispatch();
    const { errorMsg } = useSelector((state) => state.frame);
    return (<MyBoxComponent insideComp={<>
                <div className='fitt mb-4'>
                    <div>
                        <center><label className='subtitle is-4'>{errorMsg[0]}</label></center>
                        <center><label className='subtitle is-4'><a>{errorMsg[1]}</a></label></center>
                        <center><label className='subtitle is-4'>{errorMsg.slice(2).join("")}</label></center>
                    </div>
                </div>
                <button className="button is-link is-light" onClick={() => dispatch(changeFrame("EmailReco"))}>Back</button>
            </>}></MyBoxComponent>);
}
export default EmailComponent;
 